<template>
    <el-container>
        <el-header class="sumj_header">
            <div style="display:flex">
                <img :src="require('../assets/sumj_logo.png')" class="sumj_header_img">
                <el-menu mode="horizontal" class="sumj_header_menu" background-color="#409EFF" text-color="#FFFFFF" active-text-color="#E6A23C" :default-active="defaultActive" :router="true">
                    <el-menu-item index="/DeviceData" :route="{ name: 'DeviceData_Default' }">{{
                        $t("lang.设备数据") }}</el-menu-item>
                    <el-menu-item v-if="userType == 'Employee'" index="/StockManage" :route="{ name: 'StockManage_Default' }">库存管理</el-menu-item>
                    <el-menu-item v-if="userType == 'Employee'" index="/SystemSetting" :route="{ name: 'SystemSetting_Default' }">生产管理</el-menu-item>
                </el-menu>
            </div>
            <div style="display:flex;justify-content: end; align-items: center;">
                <el-dialog :title="$t('lang.修改密码')" :visible.sync="changePWDdialogVisible" width="500px" :close-on-click-modal="false" :close-on-press-escape="false" @open="errmsgVisible = false">
                    <el-alert class="sumj_yanzhengmima" :title="$t('lang.' + errmsg)" v-show="errmsgVisible" type="error" :closable="false" :show-icon="true"> </el-alert>
                    <el-form :model="form" label-position="right" label-width="85px">
                        <el-form-item :label="$t('lang.旧密码')">
                            <el-input v-model="form.oldPWD" show-password style="width:340px"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('lang.新密码')">
                            <el-input v-model="form.newPWD" show-password style="width:340px"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('lang.确认新密码')">
                            <el-input v-model="form.confirmPWD" show-password style="width:340px"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer">
                        <el-button @click="changePWDdialogVisible = false">{{ $t("lang.取消") }}</el-button>
                        <el-button type="primary" @click="changePWD">{{ $t("lang.保存") }}</el-button>
                    </span>
                </el-dialog>
                <el-dialog :title="$t('lang.修改公司名称')" :visible.sync="changecompanyNamedialogVisible" width="500px" @open="open()" :close-on-click-modal="false" :close-on-press-escape="false">
                    <el-alert :title="$t('lang.' + errmsg)" v-show="errmsgVisible" type="error" :closable="false" :show-icon="true"></el-alert>
                    <el-form :model="form1" ref="form1" label-position="right" label-width="150px" :rules="rules1">
                        <el-row :gutter="20">
                            <el-form-item :label="$t('lang.公司名称')" prop="companyName">
                                <el-input v-model="form1.companyName"></el-input>
                            </el-form-item>
                        </el-row>
                    </el-form>
                    <span slot="footer">
                        <el-button @click="changecompanyNamedialogVisible = false">{{ $t("lang.取消") }}</el-button>
                        <el-button type="primary" @click="changecompanyName()">{{ $t("lang.保存") }}</el-button>
                    </span>
                </el-dialog>
                <el-dropdown @command="handleCommand">
                    <div style="text-align:center">
                        <el-avatar icon="el-icon-user-solid"></el-avatar>
                        <div style="color:#e6a23c;">{{ username == "null" ? $t("lang.微信用户") : username }}</div>
                    </div>
                    <el-dropdown-menu>
                        <el-dropdown-item command="xiugaimima">{{ $t("lang.修改密码") }}</el-dropdown-item>
                        <el-dropdown-item command="xiugaigongsimingcheng">{{ $t("lang.修改公司名称") }}</el-dropdown-item>
                        <el-dropdown-item command="tuichu">{{ $t("lang.退出") }}</el-dropdown-item>
                        <!-- 下拉菜单的选项包括"修改密码"、"修改公司名称"和"退出"，每个选项都有一个对应的命令(command)属性，当用户选择该选项时，会将相应的命令传递给handleCommand函数。 -->
                    </el-dropdown-menu>
                </el-dropdown>
                <!-- <el-popover placement="bottom" trigger="hover" v-model="userToolVisible">
            <el-menu>
              <el-menu-item @click="changePWDdialogVisible=true">
                修改密码
              </el-menu-item>
              <el-menu-item @click="exitSystem">
                退出
              </el-menu-item>
            </el-menu>
            <p class="sumj_p_username" slot="reference">{{username=="null"?'微信用户':username}}</p>
          </el-popover>
          <i class="el-icon-caret-bottom"></i> -->
            </div>
        </el-header>
        <router-view></router-view>
    </el-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "Home",
    data() {
        let that = this;
        return {
            form: {
                oldPWD: "",
                newPWD: "",
                confirmPWD: "",
            },
            form1: {
                companyName: "",
            },
            rules1: {
                companyName: [{ required: true, message: that.$t("lang.公司名称不能为空"), trigger: "blur" }],
            },
            errmsg: "修改成功",
            errmsgVisible: false,
            userToolVisible: false,
            changePWDdialogVisible: false,
            changecompanyNamedialogVisible: false,
            username: window.localStorage.getItem("userName"),
            userType: "",
        };
    },
    components: {},
    methods: {
        open() {
            this.form1.companyName = this.$store.state.currentUser.companyName;
        },
        changePWD: function () {
            let that = this;
            this.userToolVisible = false;
            this.axios
                .post("Sys_UserInfo/ChangePWD", that.form)
                .then(function (response) {
                    that.errmsgVisible = response.data.code != 101;
                    if (response.data.code == 101) {
                        that.$message.success(that.$t("lang." + response.data.data));
                        that.changePWDdialogVisible = false;
                    } else {
                        that.errmsg = response.data.data;
                    }
                })
                .catch(function () { });
        },
        changecompanyName() {
            let that = this;
            this.$refs.form1.validate((valid) => {
                if (valid) {
                    this.userToolVisible = false;
                    this.axios
                        .post("Sys_UserInfo/SaveByColumn", { id: that.$store.state.currentUser.id, editColumn: "公司名称", editValue: that.form1.companyName })
                        .then(function (response) {
                            console.log(response.data);
                            if (response.data.code == 101) {
                                that.$message.success(that.$t("lang.修改成功"));
                                that.changecompanyNamedialogVisible = false;
                                that.getCurrentUser();
                            }
                        })
                        .catch(function () { });
                } else {
                    this.changecompanyNamedialogVisible = true;
                }
            });
        },
        handleCommand(v) {
            let that = this;
            if (v == "xiugaimima") {
                that.changePWDdialogVisible = true;
            } else if (v == "xiugaigongsimingcheng") {
                that.changecompanyNamedialogVisible = true;
            } else if (v == "tuichu") {
                that.exitSystem();
            }
        },
        exitSystem() {
            var that = this;
            this.userToolVisible = false;
            this.$confirm(that.$t("lang.确定要退出吗"), {
                closeOnClickModal: false,
                type: "warning",
                center: true,
            }).then(function () {
                that.axios.post("sys_userinfo/exitSystem").then(function (response) {
                    if (response.data.code == 101) {
                        window.localStorage.removeItem("token");
                        window.localStorage.removeItem("userName");
                        //location.href = "/";
                        that.$router.push("/login");
                    }
                });
            })
                .catch(function () { });
        },
        ...mapActions(["getCurrentUser"]),
    },
    computed: {
        defaultActive: function () {
            if (this.$route.path.indexOf("/", 10) <= 0) {
                return this.$route.path;
            } else {
                return this.$route.path.substr(0, this.$route.path.indexOf("/", 10));
            }
        },
        ...mapState(["currentUser"]),
    },
    mounted: function () {
        let that = this;
        this.getCurrentUser();
        this.axios.post("Sys_UserInfo/GetUserType").then(function (response) {
            that.userType = response.data.data;
        });
    },
};
</script>
<style scoped>
.el-container {
    height: 100%;
    overflow: hidden;
}

.sumj_header {
    background-color: #409eff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sumj_header_img {
    margin-top: 5px;
    height: 55px;
}

.sumj_header_menu {
    margin-left: 20px;
}

.sumj_yanzhengmima {
    margin-bottom: 10px;
}
  
</style>